@media (min-width: 1050px) {
  .nav-toggle-label {
    display: none;
  }

  header {
    display: inline-grid;
    grid-template-columns: 1fr auto minmax(600px, 1fr) 1fr;
    align-items: center;
  }

  .logo {
    grid-column: 1 / span 1;
  }

  .logo img {
    margin-top: 5px;
  }

  nav {
    all: unset;
    grid-column: 3 / span 4;
    display: flex;
    justify-content: flex-end;
    margin-right: 1rem;

    a {
      opacity: 1;
      position: relative;

      &::before {
        content: "";
        display: block;
        height: 5px;
        background: white;
        margin-bottom: 1em;
        top: -0.75em;
        left: 0;
        right: 0;
        transform: scale(0, 1);
        transition: transform ease-in-out 250ms;
      }

      &:hover::before {
        transform: scale(1, 1);
      }
    }

    ul {
      display: flex;
      justify-content: flex-end;
    }

    li {
      margin-left: 2em;
      margin-bottom: 1.5em;
    }
  }

  .secondary-bar {
    display: grid;
    grid-template-columns: 1fr auto minmax(600px, 1fr) 1fr;

    .secondary-bar-item {
      &:first-child {
        grid-column: 2 / span 1;
      }

      & a {
        font-size: 1.2em;
      }
    }
  }

  .fullwidth-banner > .overlay h1 {
    font-size: 2.75rem;
  }

  // .flex > img {
  //   width: 230px;
  // }

  .carousel-caption {
    bottom: 40% !important;
    left: 28% !important;
    right: 28% !important;
  }
}
