@media (max-width: 350px) {
  .footer > .container * {
    font-size: 1em;
  }
}

@media (max-width: 500px) {
  .flex img {
    width: 100%;
    align-self: center;
  }

  .container p {
    font-size: 1.2em;
  }

  .section h1 {
    font-size: 1.9em;
  }

  .fullwidth-banner {
    & .overlay > h1 {
      font-size: 1.5em;
      line-height: 1.5em;
      margin-bottom: 1.5em;
    }

    & .overlay {
      padding: 10em 1em;
    }
  }

  .contact-item-text {
    font-size: 0.9em;
  }

  .logo {
    width: 40%;
  }

  .carousel-caption {
    bottom: 5% !important;
    left: 15% !important;
    right: 15% !important;

    h3 {
      font-size: 1.2em;
      margin: 0;
    }
  }

  .top-bar {
    padding: 0.5rem;

    .details,
    .button,
    a {
      font-size: 12px;
    }
  }
}

@media (max-width: 950px) {
  .details {
    float: unset !important;
  }
}

@media (max-width: 700px) {
  .top-bar .button {
    display: block;
    max-width: 120px;
  }
}
