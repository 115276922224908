$primary: #754827;
$secondary: #a33722;
$dark: rgba(17, 17, 17, 0.95);
$red: rgba(221, 36, 36, 0.8);

@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Raleway", sans-serif;
}

body {
  background: $dark;
}

a {
  color: white;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

p {
  margin-bottom: 3rem;
}

table tbody tr {
  height: 3.5rem;
}

td {
  width: 90%;
  padding: 1.5rem 0;
}

.text-primary {
  color: $primary;
}

.text-secondary {
  color: $secondary;
}

.text-light {
  color: white;
}

.text-center {
  text-align: center;
}

.bg-primary {
  padding: 2em 0em;
  background: $primary;
}

.container,
.container-fluid {
  width: 80%;
  margin: auto;
  color: white;

  .section {
    margin: 2em 0 5em 0;

    & img {
      width: 100%;
      max-width: 1000px;
    }
  }

  p {
    font-size: 1.4em;
    line-height: 1.7em;
  }

  h1 {
    font-size: clamp(2rem, 5vw, 3.5rem);
    color: $secondary;
    text-align: center;
    margin: 1em 0;
  }

  span {
    color: $secondary;
    text-transform: uppercase;
    font-weight: bold;
  }

  &-fluid {
    width: 95%;
    margin: auto;
  }
}

header {
  background-color: $dark;
  text-align: center;
  position: sticky;
  width: 100%;
  z-index: 999;
  padding: 1rem 0;

  a:hover {
    text-decoration: none;
  }
}

nav {
  position: absolute;
  text-align: left;
  top: 100%;
  left: 0;
  background-color: $dark;
  width: 100%;
  transform: scale(1, 0);
  transform-origin: top;
  transition: transform 400ms ease-in-out;
  padding-top: 1.5rem;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    margin-bottom: 2em;
    margin-left: 1em;
  }

  a {
    font-size: 1.2rem;
    text-transform: uppercase;
    opacity: 0;
    transition: opacity 150ms ease-in-out;
  }
}

.nav-toggle {
  display: none;
}

.nav-toggle-label {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 1em;
  height: 100%;
  display: flex;
  align-items: center;

  span {
    &,
    &::before,
    &::after {
      display: block;
      background: white;
      height: 2px;
      width: 2em;
      border-radius: 2px;
      position: relative;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
    }

    &::before {
      bottom: 10px;
    }

    &::after {
      top: 10px;
    }
  }
}

.nav-toggle:checked ~ nav {
  transform: scale(1, 1);

  & a {
    opacity: 1;
    transition: opacity 150ms ease-in-out 250ms;
  }
}

.logo {
  max-width: 800px;
  margin-left: 1rem;
}

.secondary-bar {
  background: $primary;
  display: block;
  padding-top: 1em;

  .secondary-bar-item a {
    margin: 0 2em;
    font-size: 1em;
  }
}

.fullwidth-banner {
  h1 {
    text-align: center;
    color: white;
    line-height: 2em;
    text-transform: uppercase;
  }

  .overlay {
    padding: 15em 2em;
    background: rgba($primary, 0.8);
  }
}

.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 2rem;

  .full {
    max-width: 1200px;
  }

  // & div,
  // & div table {
  //   width: 100%;
  //   max-width: 450px;
  // }
}

.footer {
  padding: 3rem 0;
  background: $primary;
  color: white;
}

.footer > .container * {
  margin-bottom: 2rem;
}

.footer-item {
  color: white;
}

.form-item {
  margin-bottom: 3em;

  input,
  textarea {
    padding: 1em;
    border-radius: 8px;
    border: 1.5px solid black;
    font-size: 1em;
  }

  label {
    color: $primary;
    font-size: 1.4em;
  }

  input[type="submit"] {
    margin-top: 2em;
    width: 25%;
    min-width: 170px;
    background: $primary;
    color: white;
    border-radius: 25px;
    border: 2px solid $primary;
    font-size: 1em;
    -webkit-appearance: none;

    &:hover {
      transition: 300ms ease-in-out;
      color: $primary;
      background: white;
      cursor: pointer;
    }

    &:disabled {
      background: rgba($primary, 0.75);
      border: 2px solid rgba($primary, 0);
      color: white;
    }
  }

  * {
    display: block;
    width: 90%;
    max-width: 1200px;
  }

  span {
    font-size: 1em;
    color: green;

    &::before {
      content: "* ";
    }
  }
}

.contact-item {
  margin-bottom: 3rem;
}

.contact-item-icon {
  margin: 0 1rem 0 0;
  font-size: 2.5rem;
  color: $primary;
}

.contact-item-text {
  font-size: 2.25rem;
  color: $primary;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.center {
  width: 100%;
  margin: auto;
}

.use-bootstrap {
  @import "~bootstrap/scss/bootstrap.scss";
}

.top-bar {
  padding: 1.5rem;
  color: white;

  background: $primary;

  a {
    font-size: 1.2em;
    vertical-align: super;
  }

  .details {
    float: right;

    & span {
      vertical-align: super;
      margin-right: 1rem;
    }
  }

  .button {
    background: $dark;
    padding: 10px;
    color: white;
    border: 3px solid $dark;
    -webkit-appearance: none;
    transition: 300ms ease-in-out;
    text-decoration: none;

    &:hover {
      color: $dark;
      background: white;
      cursor: pointer;
    }
  }
}

footer {
  p {
    margin-bottom: 1rem;

    & > a {
      vertical-align: super;
    }

    & > svg {
      margin: 0 !important;
    }
  }
}

.carousel-caption {
  background: $red;
  padding: 1rem;
  bottom: 30% !important;

  h3 {
    margin: 0;
  }
}

.button-primary {
  font-size: clamp(0.8rem, 5vw, 2rem);
  padding: 1rem;
  margin-top: 1rem !important;
  background: $secondary;
  color: white;
  border-radius: 20px !important;
  border: 2px solid $secondary;
  -webkit-appearance: none;
  transition: 300ms ease-in-out;
  transition-property: color, background, border;

  &:hover {
    color: $secondary;
    background: white;
    cursor: pointer;
    border: 2px solid white;
    text-decoration: none;
  }
}

.menu-price {
  padding-left: 1rem;
}

.menu-list-item {
  border: 5px solid $primary;
  transition: all 250ms ease-in-out;
  transition-property: background-color, border;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  .item-overlay {
    width: 100%;
    margin: 0;
  }

  img {
    display: block;
    margin: auto;
  }

  h2 {
    margin: 1rem 0;
  }

  &:hover {
    background-color: $secondary;

    border: 5px solid $secondary;
  }
}

.menu-area {
  margin-bottom: 2rem;

  img {
    display: block;
    margin: auto;
    width: 100%;
    max-width: 1000px;
  }
}

.gallery-item {
  width: 100%;
  max-width: 500px;
  margin: 2rem 1rem;

  .item-title {
    padding: 1rem 1rem;
    background-color: #a33722;
    margin: 0;
    color: white;
  }

  img {
    display: block;
    width: 100%;
    margin: 0;
  }

  p {
    font-size: 1rem;
    text-transform: uppercase;
  }
}

.contact-item {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1rem;
  font-size: 1.5rem;
  margin: 2rem 0;
}

.gallery {
  grid-gap: 0;
  gap: 0;
}

@import "desktop-view.scss";
@import "mobile-view.scss";
